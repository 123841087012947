define("discourse/plugins/kista_bbb/kista-bbb/controllers/insert-bbb", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      //const cu=this.apiobj.getCurrentUser(), dsettings=cu.siteSettings;
      const cu = window.cu,
        dsettings = cu.siteSettings;
      var servers = [1, 2, 3].map(i => Object.fromEntries("active,name,groups,chans".split(",").map(n => {
          var r = dsettings[`kista_bbb_srv${i}_${n}`];
          if (n == "name" && !r) {
            r = `Server ${i}`;
          }
          if (n == "groups" || n == "chans") {
            r = r.split("|").filter(x => x !== "").map(Number);
          }
          return [n, r];
        }).concat([["num", i]]))),
        filteredservers = servers.filter(x => x.active && x.groups.reduce((a, b) => a || cu.groups.map(x => x.id).indexOf(b) > -1, !x.groups.length));
      this.setProperties({
        title: "",
        servers: filteredservers.map(x => ({
          name: x.name,
          value: x.num
        })),
        offerservers: filteredservers.length > 1
      });
      this.setProperties({
        server: this.get("servers.firstObject.value")
      });
    },
    genpw() {
      return Math.random().toString(36).slice(-8);
    },
    insertDisabled(title) {
      return (0, _utils.isEmpty)(title);
    },
    actions: {
      insert() {
        const btnTxt = this.buttonText ? ` label="${this.buttonText}"` : "";
        this.toolbarEvent.addText(`[wrap=bbb Titel="${this.title}"${btnTxt} pp="${this.genpw()}-${this.genpw()}"${this.hasOwnProperty("server") && this.server != 1 ? ' sr="' + this.server + '"' : ''}][/wrap]`); // mobileIframe="${ this.mobileIframe }" desktopIframe="${this.desktopIframe}"
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, [["method", "insertDisabled", [(0, _decorators.default)("title")]]]));
});