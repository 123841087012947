define("discourse/plugins/kista_bbb/kista-bbb/controllers/add-guest", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        // group: this.fullgroupname,
      });
      this.setProperties({
        // server: this.get("servers.firstObject.value"),
      });
    },
    saveDisabled(name) {
      return (0, _utils.isEmpty)(name) || /[\?]/i.test(name); // || !/^2[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(validto);
    },
    actions: {
      resend() {
        this.send("closeModal");
      },
      //@discourseComputed("name")
      save() {
        this.retfunc(this.name);
        this.name = "";
        this.send("closeModal");
      },
      deactivate() {
        this.name = "";
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, [["method", "saveDisabled", [(0, _decorators.default)("name")]]]));
});